<template>
    <div class="d-inline-block">
        <b-button type="button" size="sm" title="Import Csv" variant="outline-info" @click="handleImportPopupClick()">
            <i class="fe fe-file"></i>
        </b-button>
        <a-drawer
            placement="right"
            :width="'90%'"
            :wrapStyle="{overflow: 'auto', paddingBottom: '108px'}"
            @close="handleImportPopupClose"
            :visible="visible"
            :zIndex="100"
            :title="$t('title.importCSV')"
        >
            <div>
                <b-row>
                    <b-col sm="6">
                        <upload v-model="formFields.csv"
                                :disabled="formFields.csv"
                                title="Upload Csv" css-class="mr-2 btn-sm" upload-type="csv" type="button"></upload>
                        <b-button type="button" size="sm" :title="$t('button.title.removeUpload')"
                                  variant="outline-primary" class="mr-2"
                                  @click="handleClearCsvClick" :disabled="!formFields.csv">
                            <i class="fa fa-close"></i>
                        </b-button>
                        <b-button type="button" size="sm" color="outline-primary" class="mr-2"
                                  :disabled="!(formFields.csv && formFields.csv.download_url)"
                                  :href="(formFields.csv ? formFields.csv.download_url : '')" target="_blank">
                            <i class="fa fa-download"></i>
                        </b-button>
                        <b-button type="button" size="sm" color="outline-primary" class="mr-2"
                                  :href="`${baseUrl}/assets/${filename}`">
                            {{$t('title.downloadSample')}}
                        </b-button>
                        <b-button type="button" size="sm" color="outline-primary" class="mr-2"
                                  :title="$t('button.title.import')"
                                  v-if="dataToImport.length > 0"
                                  :disabled="global.pendingRequests > 0"
                                  @click="handleImportClick">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px"
                                         v-if="global.pendingRequests > 0"></clip-loader>
                            {{$t('button.import')}}
                        </b-button>
                        <div>
                            <p class="mt-1 text-danger" v-if="hasError">
                                {{$t('validation.pleaseFixTheIssueBelow')}}.</p>
                            <b-form-invalid-feedback :state="(!(formErrors.has('csv')))">
                                {{ formErrors.first('csv') }}
                            </b-form-invalid-feedback>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <hr/>
                        <div>
                            <div class="table-responsive">
                                <template v-if="dataToImport && dataToImport[0] !== undefined">
                                    <table class="table table-striped">
                                        <thead>
                                        <tr>
                                            <th width="20">#</th>
                                            <th width="50">{{$t('column.brand')}}</th>
                                            <th width="50">{{$t('column.model')}}</th>
                                            <th width="50">{{$t('column.configCode')}}</th>
                                            <th width="50">{{$t('column.additionalEquipment')}}</th>
                                            <th width="50">{{$t('column.fuel')}}</th>
                                            <th width="50">{{$t('column.length')}}</th>
                                            <th width="50">{{$t('column.width')}}</th>
                                            <th width="50">{{$t('column.height')}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(tr, index) in dataToImport">
                                            <td>
                                                {{ index + 1 }}
                                            </td>
                                            <td>
                                                {{tr.data.brand}}
                                                <div v-if="tr.errors[0]">
                                                    <div v-for="error in tr.errors[0]">
                                                        <small class="text-danger">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{tr.data.model}}
                                                <div v-if="tr.errors[1]">
                                                    <div v-for="error in tr.errors[1]">
                                                        <small class="text-danger">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {{tr.data.config_code}}
                                                <div v-if="tr.errors[2]">
                                                    <div v-for="error in tr.errors[2]">
                                                        <small class="text-danger">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{tr.data.additional_equipment}}
                                                <div v-if="tr.errors[3]">
                                                    <div v-for="error in tr.errors[3]">
                                                        <small class="text-danger">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{tr.data.fuel}}
                                                <div v-if="tr.errors[4]">
                                                    <div v-for="error in tr.errors[4]">
                                                        <small class="text-danger">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{tr.data.length}}
                                                <div v-if="tr.errors[5]">
                                                    <div v-for="error in tr.errors[5]">
                                                        <small class="text-danger">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{tr.data.width}}
                                                <div v-if="tr.errors[6]">
                                                    <div v-for="error in tr.errors[6]">
                                                        <small class="text-danger">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{tr.data.height}}
                                                <div v-if="tr.errors[7]">
                                                    <div v-for="error in tr.errors[7]">
                                                        <small class="text-danger">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import {request} from "../../util/Request";
    import Error from "../../util/Error"
    import {mapState} from "vuex";

    const DEFAULT_STATE = {
        id: null,
        vendor_id: null,
        status: null,
        csv: null
    }

    export default {
        data() {
            return {
                baseUrl: process.env.VUE_APP_API_URL,
                formErrors: new Error({}),
                formFields: {...DEFAULT_STATE},
                visible: false,
                dataToImport: [],
                filename: `vehicle_models.csv`,
                hasError: false
            }
        },
        methods: {
            handleClearCsvClick() {
                this.dataToImport = []
                this.formFields = {...DEFAULT_STATE}
            },
            handleImportPopupClick() {
                this.visible = true
            },
            handleImportPopupClose() {
                this.visible = false
                this.formFields = {...DEFAULT_STATE}
                this.dataToImport = []
                this.$emit('refresh')
            },
            getCsvDetailed(id) {
                this.hasError = false

                request({
                    method: "get",
                    url: `/models/csv/${id}/validate`,
                })
                    .then((response) => {
                        const {data} = response
                        const self = this
                        const dataToImport = data.map(item => {
                            self.hasError = (!self.hasError) ? (Object.entries(item.errors).length > 0) : true;

                            return {
                                errors: item.errors,
                                data: JSON.parse(item.data),
                            }
                        });

                        self.dataToImport = dataToImport
                    })
                    .catch((errors) => {
                    })
            },
            handleImportClick() {
                if (this.dataToImport.length <= 0) {
                    return false;
                }

                request({
                    url: 'models/csv/import',
                    method: "post",
                    data: {
                        ...this.formFields,
                    }
                })
                    .then((response) => {
                        const {data} = response
                        this.csvImportSuccess(data.rows, data.updateRows)
                        this.handleImportPopupClose()
                    })
                    .catch((errors) => {
                        if (errors.status && errors.status === 422) {
                            this.formErrors = new Error(errors.data.errors)
                            return false
                        }

                        this.handleServerError(errors)
                    })
            },
        },
        watch: {
            'formFields.csv': function (newVal, oldVal) {
                if (newVal && newVal !== null && newVal.id) {
                    this.getCsvDetailed(newVal.id)
                }
            }
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
